<template>
  <grid
    item-min-width="300px"
    class="align-center"
  >
    <column-picker
      :selected-col-id.sync="computedCmp"
      :filters="filters"
      :label="$t(`t.Comparator`)"
      :attach="false"
      :show-icon="true"
      :rules="required"
      cmp-cols-only
      currency-disabled
    />
    <column-picker
      :selected-col-id.sync="computedCol"
      :filters="filters"
      :label="$t(`t.Selector`)"
      :attach="false"
      :show-icon="true"
      clearable
      currency-disabled
    />
  </grid>

</template>

<script>
export default {
  inheritAttrs: false,
  components: {
    ColumnPicker: () => import('@/components/column-picker'),
    Grid: () => import('@/components/grid')

  },
  computed: {
    computedCmp: {
      get () { return this.dataCmp },
      set (v) {
        this.dataCmp = v
        this.dataDocumentEmit()
      }
    },
    computedCol: {
      get () { return this.dataCol },
      set (v) {
        this.dataCol = v
        this.dataDocumentEmit()
      }
    },
    filters () {
      return { 'document-types': [[`${this.targetDocTy}`], []] }
    }
  },
  data () {
    return {
      dataCmp: null,
      dataCol: null,
      dataDocumentEmit: this.$nextTickDedup(this.emitDocument),
      required: [v => !!v || this.$t('t.IsRequired')]
    }
  },
  methods: {
    emitDocument () {
      const doc = { cmp: this.dataCmp, col: this.dataCol }

      if (!this.lodash.isEqual(doc, this.document)) {
        this.$emit('update:document', this.lodash.cloneDeep(doc))
      }
    }
  },
  props: {
    document: Object,
    targetDocTy: String
  },
  watch: {
    document: {
      handler (v) {
        const m = this.lodash.cloneDeep(v)
        this.dataCmp = m?.cmp
        this.dataCol = m?.col
        this.dataDocumentEmit()
      },
      immediate: true
    }
  }
}
</script>
